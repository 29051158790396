import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/zap-onboard-api/zap-onboard-api/services-unmanaged/zap-onboard-home-page/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Screen13 } from '../../../../components/images/support/xero/Screen13.generated';
import { Screen14 } from '../../../../components/images/support/xero/Screen14.generated';
import { Screen1 } from '../../../../components/images/support/xero/Screen1.generated';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "disconnecting-from-xero-"
    }}>{`Disconnecting from Xero 🔗`}</h1>
    <p>{`Follow this guide to disconnect Canyou from Xero.`}</p>
    <hr></hr>
    <ol>
      <li parentName="ol">{`Navigate to the settings menu, then click the `}<inlineCode parentName="li">{`Disconnect Xero`}</inlineCode>{` button.`}</li>
    </ol>

    <Screen13 mdxType="Screen13" />
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Confirm you wish to disconnect.`}</li>
    </ol>

    <Screen14 mdxType="Screen14" />
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`You can now reconnect from the settings menu at any time.`}</li>
    </ol>

    <Screen1 mdxType="Screen1" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      